.who-we-are {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.full-screen-image {
    width: 100%;
    height: 100vh; 
    background-image: url('../../assets/tallBuilding.png'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow::before {
    content: "→";
    margin-right: 30px;
}

.logo {
    width: 500px; /* Adjust as needed for a bigger size */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; 
    margin-bottom: 150px;
    z-index: 1;
}

@media (max-width: 800px) {
    .logo {
        width: 100%; /* Adjust the logo size for smaller screens */
        margin-bottom: 350px;
    }
}

.whoweare-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    padding: 100px 50px; /* Top/Bottom padding is 100px, Left/Right padding is 50px */
    background-color:#EEECDE;
    color: black;
    gap: 20px;
}

.title {
    align-self: flex-start;
    justify-self: center;
    font-size: 50px;
    font-weight: 600;
    margin-left: 20px;
}

.description {
    align-self: center;
    justify-self: center;
    font-size: 24px;
}

.roomImage {
    max-width: 100%;
    height: auto;
}

@media (max-width: 800px) {
    .whoweare-container {
        grid-template-columns: 1fr;
        gap: 0;
        margin-left: 0;
        padding: 40px 40px;
    }

    .title {
        margin-bottom: 40px;
            align-self: center;
    }

    .logo{
        width: 300px;
        height: auto; /* Maintain aspect ratio */
        margin-bottom: 200px;
    }
}
