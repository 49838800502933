.contacts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-image: url('../../assets/12.png');
    background-size: cover;
    background-position: bottom;
    color: black;
    min-height: 780px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 80px;
}

.contact-info {
    font-size: 24px;
    width: 100%;
    margin-top: 100px;
}

.contact-info h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.SRGroup-logo {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-top: 100px;
}

@media (max-width: 800px) {
    .contacts-container {
        align-items: center;
        justify-content: flex-start;
        padding-left: 0; /* Remove left padding on small screens */
    }

    .contact-info {
        text-align: center;
    }

    .SRGroup-logo {
        margin-top: 100px;
    }
}
