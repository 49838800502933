*{
  margin: 0;
  padding: 0;
} 

body{
  height: 100vh;
  background: #1b1a1c;
  color:white;
  font-family: 'Merriweather';
}

html {
  scroll-behavior: smooth;
}

