.services-container {
    width: 100%;
    background-color:#EEECDE;
    color: black;
    padding-bottom: 1px;
}

.services-container h1 {
    align-self: flex-start;
    justify-self: center;
    font-size: 50px;
    font-weight: 600;
    margin-left: 60px;
    padding-top: 50px;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 60px; 
    column-gap: 80px; 
    margin: 60px 60px 50px 60px;
}

@media (max-width: 650px) {
    .services-grid {
        grid-template-columns: 1fr;
    }
}

.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service-item img {
    width: 100%;
    height: auto;
}

.service-item h3 {
    margin-top: 10px;
    font-size: 24px;
}

.flex-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
}

.services-text {
    display: flex;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 50px;
    font-weight: 600;
}

.flex-item img {
    width: 100%;
    height: auto;
}

@media (max-width: 1200px) {
    .flex-container {
        grid-template-columns: 1fr;
    }
    .services-text {
        font-size: 35px;
        margin-bottom: 30px;
    }
}

.properties-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 60px;
    margin: 60px;
}

.property-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.property-content {
    border: 3px solid black;
}

.property-content img {
    width: 100%;
    height: auto;
}

.property-content h3 {
    margin-top: 10px;
    font-size: 25px;
    padding: 0 5px 5px 5px;
}

.property-content p {
    margin-top: 5px;
    font-size: 20px;
    padding: 0 5px 5px 5px;
}

@media (max-width: 1000px) {
    .properties-grid {
        grid-template-columns: 1fr;
    }
}